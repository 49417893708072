<template>
  <base-layout>
    <slot />
    <template #after-main>
      <!-- @vue-ignore -->
      <!--      <NuxtNotifications-->
      <!--        position="top right"-->
      <!--        width="500"-->
      <!--        pause-on-hover-->
      <!--      />-->
    </template>
  </base-layout>
</template>

<script setup lang="ts">
</script>
