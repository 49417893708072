<template>
  <Html v-bind="head.htmlAttrs || {}" class="app">
    <Head>
      <Title>{{ title }}</Title>
      <Link
        v-for="link in head.link"
        :id="link.id"
        :key="link.id"
        :rel="link.rel"
        :href="link.href"
        :hreflang="link.hreflang"
      />
      <Meta v-for="meta in head.meta" :id="meta.id" :key="meta.id" :property="meta.property" :content="meta.content" />
    </Head>
    <Body>
      <v-app class="position-relative">
        <slot />
      </v-app>
    </Body>
    <slot name="after-main" />
    <!-- @vue-ignore -->
    <TheNotifications
      position="top right"
      width="500"
      pause-on-hover
    />
  </Html>
</template>

<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core";
import { useTheme } from "vuetify";
const { t } = useI18n();
const route = useRoute();
const appConfig = useAppConfig();

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true
});

const title = computed<string>(() => {
  const part = route.meta.title ? t(route.meta.title as string) : "";
  return part ? `${appConfig.baseTitle} - ${part}` : appConfig.baseTitle as string;
});

const theme = useTheme();
const preferredTheme = useLocalStorage("preferredTheme", "light");

onMounted(() => {
  theme.global.name.value = preferredTheme.value;
});
</script>
